import React from "react"
import { Router } from "@reach/router"
import DeliveryDetails from "components/Epharmacy/DeliveryDetails"
import DeliveryDetailsSummary from "components/Epharmacy/DeliveryDetailsSummary"
import DeliveryDetailsCompleted from "components/Epharmacy/DeliveryDetailsCompleted"
import Epharmacy from "components/Epharmacy"

export default () => {
  return (
    <Router basepath="/epharmacy">
      <DeliveryDetails path="/delivery-details" />
      <DeliveryDetailsSummary path="/summary" />
      <DeliveryDetailsCompleted path="/completed" />
      <Epharmacy path="/" />
    </Router>
  )
}
